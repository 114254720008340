<template>
	<div class="about-page">
		<div class="inner-page" @scroll="pageScroll($event)">
			<div class="content-container pr-0">
				<div class="indent fixed-width mb-5">
					<h1><span id="about-title" class="txtan">Mickie C. Storm </span><span id="about-title-2" class="txtan light">Creative Director</span></h1>
					<p class="txtan" id="about-bread">I'm a Technical Creative Director with 15+ years of global hands-on experience. For the past 11 years, I have lived in Spain, London, and Los Angeles and helped numerous businesses in a digital world – always through solutions with clear objectives and measurable results. My deep passion for all areas of design and code allows me to form the right team and find the most cost-effective solution for projects of all sizes.</p>
					<router-link to="/work-portfolio" class="btn icon business multi">Portfolio</router-link>
					<a class="btn icon download" target="_blank" href="/files/cv-mickie-c-storm.pdf">CV (PDF)</a>
				</div>
				<div class="indent">
					<ul class="tabs-horizontal">
						<li><a @click="changeTab($event,'experience')" :class="{active: (this.activeTab == 'experience')}" href="#">Experience</a></li>
						<li><a @click="changeTab($event,'skills')" :class="{active: (this.activeTab == 'skills')}" href="#">Skillset</a></li>
						<li><a @click="changeTab($event,'profile')" :class="{active: (this.activeTab == 'profile')}" href="#">Psychometrics</a></li>
						<li><a @click="changeTab($event,'compliance')" :class="{active: (this.activeTab == 'compliance')}" href="#">Background</a></li>
					</ul>
				</div>
				<div class="tabs-container">
					<div id="experience" class="tab-content indent" :class="{show: (this.activeTab == 'experience'), collapse: (this.activeTab != 'experience')}">
						<ul class="list-history">
							<HistoryListItem  
								v-for="(item, index) in jobExperiences" 
								:key="index"
								:index="index"
								:uniqueId="item.id"
								:companyName="item.attributes.companyName"
								:companyType="item.attributes.companyType"
								:jobTitle="item.attributes.jobTitle"
								:startDate="item.attributes.startDate"
								:endDate="item.attributes.endDate"
								:city="item.attributes.city"
								:country="item.attributes.country"
								:content="item.attributes.content"
								:image="item.attributes.companyLogo.data.attributes.url"
								:description="item.attributes.description"
							/>
						</ul>
					</div>
					<div id="skills" class="tab-content indent fixed-width" :class="{show: (this.activeTab == 'skills'), collapse: (this.activeTab != 'skills')}">
						
						<div class="column">

							<div class="section">
								<h3>Design & Illustration</h3>
								<ul>
									<li class="level-4"><span>Adobe Photoshop</span> <span>+16 Years</span></li>
									<li class="level-4"><span>Adobe Illustrator</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Adobe Indesign</span> <span>+14 Years</span></li>
									<li class="level-3"><span>Adobe Acrobat Pro</span> <span>+15 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>UI/UX</h3>
								<ul>
									<li class="level-4"><span>Figma</span> <span>+2 Year</span></li>
									<li class="level-3"><span>Adobe XD</span> <span>+4 Years</span></li>
									<li class="level-3"><span>Sketch</span> <span>+3 Years</span></li>
									<li class="level-1"><span>Invision</span> <span>+3 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>Video & Photography</h3>
								<ul>
									<li class="level-3"><span>Photography</span> <span>+10 Years</span></li>
									<li class="level-3"><span>Cinematography</span> <span>+9 Years</span></li>
									<li class="level-4"><span>Adobe Premier Pro</span> <span>+10 Years</span></li>
									<li class="level-4"><span>Adobe After Effects</span> <span>+9 Years</span></li>
									<li class="level-2"><span>Final Cut Pro</span> <span>+2 Years</span></li>
									<li class="level-4"><span>Adobe Lightroom</span> <span>+13 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>Audio & Music</h3>
								<ul>
									<li class="level-3"><span>Logic Pro X</span> <span>+10 Years</span></li>
									<li class="level-3"><span>Cubase Pro</span> <span>+10 Years</span></li>
									<li class="level-1"><span>Pro Tools</span> <span>+10 Years</span></li>
									<li class="level-4"><span>Piano</span> <span>+20 Years</span></li>
									<li class="level-3"><span>Guitar</span> <span>+16 Years</span></li>
									<li class="level-2"><span>Bass</span> <span>+16 Years</span></li>
									<li class="level-1"><span>Drums</span> <span>+8 Years</span></li>
									<li class="level-3"><span>Production</span> <span>+15 Years</span></li>
									<li class="level-3"><span>Sound Design</span> <span>+15 Years</span></li>
									<li class="level-3"><span>Mixing</span> <span>+15 Years</span></li>
									<li class="level-1"><span>Mastering</span> <span>+10 Years</span></li>
								</ul>
							</div>


						</div>

						<div class="column">
							
							<div class="section">
								<h3>Development Languages</h3>
								<ul>
									<li class="level-4"><span>HTML/CSS</span> <span>+16 Years</span></li>
									<li class="level-4"><span>SCSS/SASS/LESS</span> <span>+16 Years</span></li>
									<li class="level-4"><span>Javascript</span> <span>+16 Years</span></li>
									<li class="level-1"><span>Python</span> <span>+5 Years</span></li>
									<li class="level-3"><span>SQL</span> <span>+13 years</span></li>
									<li class="level-4"><span>PHP</span> <span>+13 Years</span></li>
									<li class="level-2"><span>VB6</span> <span>+16 Years</span></li>
									<li class="level-2"><span>Classic ASP</span> <span>+16 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>Development Frameworks</h3>
								<ul>
									<li class="level-4"><span>Laravel</span> <span>+4 Years</span></li>
									<li class="level-4"><span>Wordpress</span> <span>+7 Years</span></li>
									<li class="level-4"><span>Woocommerce</span> <span>+7 Years</span></li>
									<li class="level-4"><span>jQuery</span> <span>+6 Years</span></li>
									<li class="level-3"><span>Node.js</span> <span>+6 Years</span></li>
									<li class="level-3"><span>Vue.js</span> <span>+2 Years</span></li>
									<li class="level-3"><span>Nuxt.js</span> <span>+2 Years</span></li>
									<li class="level-1"><span>React</span> <span>+2 Years</span></li>
									<li class="level-1"><span>React Native</span> <span>+1 Years</span></li>
									<li class="level-2"><span>Ionic</span> <span>+1 Years</span></li>
									<li class="level-3"><span>Cordova</span> <span>+4 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>Development Other</h3>
								<ul>
									<li class="level-2"><span>Linux SysOp</span> <span>+10 Years</span></li>
									<li class="level-2"><span>Apache</span> <span>+10 Years</span></li>
									<li class="level-1"><span>Networking/DNS</span> <span>+10 Years</span></li>
									<li class="level-3"><span>AWS</span> <span>+8 Years</span></li>
									<li class="level-2"><span>DigitalOcean</span> <span>+4 Years</span></li>
									</ul>
							</div>

							<div class="section">
								<h3>3D & Game Design</h3>
								<ul>
									<li class="level-3"><span>3ds Max</span> <span>+10 Years</span></li>
									<li class="level-1"><span>Maya</span> <span>+2 Years</span></li>
									<li class="level-1"><span>Realflow</span> <span>+2 Years</span></li>
									<li class="level-3"><span>Vray</span> <span>+8 Years</span></li>
									<li class="level-2"><span>Arnold</span> <span>+2 Years</span></li>
									<li class="level-2"><span>Unreal Engine</span> <span>+5 Years</span></li>
									<li class="level-1"><span>Unity</span> <span>+3 Years</span></li>
								</ul>
							</div>

						</div>

						<div class="column">


							<div class="section">
								<h3>Admin & Leadership</h3>
								<ul>
									<li class="level-3"><span>Leadership</span> <span>+11 Years</span></li>
									<li class="level-3"><span>Project Management</span> <span>+11 Years</span></li>
									<li class="level-3"><span>Financial Reporting</span> <span>+11 Years</span></li>
									<li class="level-3"><span>Financial Forecast</span> <span>+11 Years</span></li>
									<li class="level-3"><span>Fund Raising</span> <span>+8 Years</span></li>
									<li class="level-2"><span>Legal & Contracts</span> <span>+11 Years</span></li>
									<li class="level-3"><span>MS Word</span> <span>+15 Years</span></li>
									<li class="level-4"><span>MS Excel</span> <span>+15 Years</span></li>
									<li class="level-4"><span>MS Powerpoint</span> <span>+15 Years</span></li>
									<li class="level-3"><span>Jira</span> <span>+8 Years</span></li>
									<li class="level-3"><span>Confluence</span> <span>+8 Years</span></li>
									<li class="level-4"><span>Trello</span> <span>+6 Years</span></li>
									<li class="level-2"><span>Azure Devops</span> <span>+2 Years</span></li>
									<li class="level-1"><span>MS Project</span> <span>+2 Years</span></li>
									<li class="level-2"><span>MS Sharepoint</span> <span>+2 Years</span></li>
								</ul>
							</div>

							<div class="section">
								<h3>Marketing & Branding</h3>
								<ul>
									<li class="level-4"><span>Corporate Visual Identities</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Brand Manuals</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Ads (online/offline)</span> <span>+14 Years</span></li>
									<li class="level-3"><span>PPC (FB/IG, Adwords/YT)</span> <span>+8 Years</span></li>
									<li class="level-4"><span>Strategic Campaigns</span> <span>+14 Years</span></li>
									<li class="level-3"><span>Copywriting</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Content Creation</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Social Media Strategies</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Behavioral Analytics</span> <span>+14 Years</span></li>
									<li class="level-3"><span>Neuro Marketing</span> <span>+8 Years</span></li>
									<li class="level-2"><span>SEO</span> <span>+12 Years</span></li>
								</ul>
							</div>
							
							<div class="section">
								<h3>Print</h3>
								<ul>
									<li class="level-4"><span>Print Material</span> <span>+14 Years</span></li>
									<li class="level-4"><span>Large-scale Signage</span> <span>+14 Years</span></li>
									<li class="level-3"><span>Book Authoring</span> <span>+14 Years</span></li>
								</ul>
							</div>


						</div>

					</div>
					<div id="profile" class="tab-content indent" :class="{show: (this.activeTab == 'profile'), collapse: (this.activeTab != 'profile')}">
						
						<div class="psy-summary">
								<div>
									<h3 class="title text-normal">Big Five</h3>
									<a href="https://bigfive-test.com/result/61e8cc3494a99c0009feca3a" target="_blank">
										<div class="content">
											<div id="chart-big-five"></div>
										</div>
									</a>
								</div>
							<div>
								<h3 class="title text-normal">Meyer-Briggs</h3>
								<a href="https://www.16personalities.com/enfp-personality" target="_blank">
									<div class="content">
										<h4 class="title text-large">ENFP-A</h4>
										<span class="title">Assertive Campaigner</span>
									</div>
								</a>
							</div>
							
						</div>

						<div class="hmd-title">
							<h3 class="title text-larger">Human Developer™ Profile <span>ID: 95803</span></h3>

						</div>
						<div class="hmd-content">
							<span class="leadin">Examined by:</span>
							<ul class="col-list">
								<li>Benny Karpatschof<span>Dr.phil.cand.stat. Lecturer emeritus at Copenhagen University.</span></li>
								<li>Carsten Rosenberg Hansen<span>Cand.pæd.psych. Lecturer emeritus at Copenhagen University.</span></li>
								<li>Jane Bruun<span>Cand.psych.aut. Director, Human Developer, test and occupational psychologist.</span></li>
								<li>Sten Frantzen<span>Cand.psych.aut. Test Developer, Human Developers and clinical psychologist</span></li>
							</ul>
						</div>


						<div id="chart"></div>
						<span class="text-gray-500" style="display:block; margin-top:30px">The complete 30-page report is available upon request.</span>
					</div>
					<div id="compliance" class="tab-content indent" :class="{show: (this.activeTab == 'compliance'), collapse: (this.activeTab != 'compliance')}">
						<h3 class="title text-normal">Background Check</h3>
						<div class="clearcheck">
							<div>
								<img alt="ClearCheck logo" src="../assets/images/clearcheck-white.svg">
								<span>Background checks are provided by <a href="https://clearcheck.com" target="_blank">ClearCheck</a>. <span class="sub">Last background check was performed Janaury 5th, 2021. </span></span>
							</div>
							<div class="hr"></div>
						</div>
						<div id="records" class="records">
							<span>Loading records from ClearCheck...</span>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="page-background" id="background-video">
			<video class="show fadeInAnimation" autoplay playsinline muted loop @loadeddata="videoLoaded()" poster="../assets/videos/bg3.jpg">
				<source src="../assets/videos/bg3.webm" type="video/webm">
				<source src="../assets/videos/bg3.mp4" type="video/mp4">
			</video>
		</div>
	</div>
</template>

<script>

	import HistoryListItem from "@/components/history/HistoryListItem.vue";
	import axios from 'axios';
	import * as echarts from 'echarts';

	export default {
		name: 'Portfolio',
		components: {
			HistoryListItem,
		},
		data() {
			return {
				videoReady: false,
				activeTab: 'experience',
				jobExperiences: [],
				pageScrollPos: 0,
			};
		},
		mounted(){
			this.$store.commit('setLoading', true);
			this.$nextTick(function () {
				this.textAnimation();
				// Get Work Experience items
				axios
					.get(this.$store.state.app.apiUrl + '/job-expriences?populate=*&sort=startDate:desc')
					.then(response => {
						this.$store.commit('setLoading', false);
						this.jobExperiences = response.data.data;
				});

				// HUMAN DEVELOPER CHART

					// initialize the echarts instance
					this.myChart = echarts.init(document.getElementById('chart'), null, { 
						renderer: 'svg',
						width: 1400,
						height: 1100
					});

					// Chart options
					this.myChart.setOption({
						angleAxis: {
							type: 'category',
							data: ["             Openness", "Innovative", "Intuition", "Holistic", "Detail orientation", "Local/global\nperspective", "Abstract thinking", "Logical thinking", "Emotional thinking", "Need for rules\n& structure", "Independence", "Need for recognition", "Situational\nawareness", "Empathy           ", "Engagement\n& energy", "Visibility", "Determination", "Persuasiveness", "Action\n orientation", "Risk\n willingness", "Confidence", "Stress\nmanagement", "Dominance", "Deligation", "Responsibility\nmanagement", "Conflict\nmanagement     "],
							splitLine: {
								show: true,
								lineStyle: {
									color: 'rgba(255,255,255,.25)',
									width: 1
								}
							},
							axisLabel: {
								interval: 0,
								inside: false,
								margin: 25,
								fontSize: 16,
								lineHeight: 18,
								fontFamily: 'Megatoya-Ext-sub',
								formatter: function (value) {
									return value.toUpperCase();
								}
							},
							boundaryGap: true,
							nameGap: 15,
							nameLocation: 'start',
							z: 100
						},
						radiusAxis: {
							interval: 10,
							max: 100,
							minorTick: {
								show: true,
								splitNumber: 2
							},
							z: 3,
							minorSplitLine: {
								show: true,
								lineStyle: {
									color: 'rgba(255,255,255,.05)',
									width: 1
								}
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: 'rgba(255,255,255,.15)',
									width: 1
								}
							},
							splitArea: {
								show: true,
								areaStyle: {
									color: [
										"rgba(0,0,0,0.0)",
										"rgba(0,0,0,0.02)"
									]
								}
							},
							nameGap: 15,
							axisTick: {
								show: true
							},
							axisLabel: {
								show: true,
								margin: 10,
								fontSize: 14
							}
						},
						tooltip: {
							show:true,
							trigger: 'item',
							className: 'charttooltip',
						},
						polar: {
							radius: '80%'
						},
						series: [{
							type: 'bar',
							data: [
								{name: 'Openness', groupId: 'Idea phase', value: 90, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Innovative', groupId: 'Idea phase', value: 80, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Intuition', groupId: 'Idea phase', value: 35, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Holistic', groupId: 'Focus phase', value: 95, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Detail oriented', groupId: 'Focus phase', value: 75, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Local/global perspective', groupId: 'Focus phase', value: 85, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Abstract thinking', groupId: 'Analysis phase', value: 35, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Logical thinking', groupId: 'Analysis phase', value: 80, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Emotional thinking', groupId: 'Analysis phase', value: 60, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Need for rules and structure', groupId: 'Determination style', value: 20, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Independence', groupId: 'Determination style', value: 95, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Need for recognition', groupId: 'Determination style', value: 85, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Situational awareness', groupId: 'a. Execution potential', value: 95, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Empathy', groupId: 'a. Execution potential', value: 95, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Engagement and energy', groupId: 'a. Execution potential', value: 85, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Visibility', groupId: 'b. Execution potential', value: 95, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Determination', groupId: 'b. Execution potential', value: 65, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Persuasiveness', groupId: 'b. Execution potential', value: 95, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Action orientation', groupId: 'a. Realization', value: 75, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Willing ness to take risks', groupId: 'a. Realization', value: 95, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Confidence', groupId: 'a. Realization', value: 95, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Stress management', groupId: 'a. Realization', value: 55, itemStyle: {color: 'rgba(55,55,55,1)'}, label: {show:false}},
								{name: 'Dominance', groupId: 'b. Realization', value: 80, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Delegation', groupId: 'b. Realization', value: 40, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Responsibility management', groupId: 'b. Realization', value: 85, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
								{name: 'Conflict management', groupId: 'b. Realization', value: 90, itemStyle: {color: 'rgba(40,40,40,1)'}, label: {show:false}},
							],
							tooltip: {
								formatter: function (params) {
									const diff = (params.data.value > 49) ? '+' + Math.abs(50 - params.data.value) : '-' + Math.abs(50 - params.data.value);
									return '<span class="groupid">' + params.data.groupId + '</span><span class="values"><strong>' + params.data.name + ': ' + params.data.value + '</strong></span><span class="info">(' + diff + ' vs. Average Subject)</span>';
								}
							},
							coordinateSystem: 'polar',
							progressiveChunkMode: 'sequential',
							barGap: "0%",
							barWidth: "100%"
						}],
						legend: {
							show: false,
							data: ['A', 'B', 'C']
						}

					});


				// BIG FIVE CHART

					// initialize the echarts instance
					this.bigFiveChart = echarts.init(document.getElementById('chart-big-five'), null, { 
						renderer: 'svg'
					});

					this.bigFiveChart.setOption({
						title: {
							show:false,
						},
						tooltip: {
							show:false,
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {
							show:false,
						},
						grid: {
							left: '0%',
							right: '10px',
							bottom: '0%',
							top: '0%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01],
							axisLabel: {
								fontSize: '0.7rem',
								lineHeight: 10,
								fontFamily: 'Megatoya-Ext-sub',
								fontWeight: 'bold',
							},
							axisTick: {
								show: true,
								inside: false,
								length: 5,
								lineStyle: {
									color: 'rgba(255,255,255,.25)',
									width: 1
								}
							},
							minorTick: {
								show: false,
								lineStyle: {
									color: 'rgba(255,255,255,.05)',
									width: 1
								}
							},
							splitLine: {
								show: true,
								splitNumber: 1,
								lineStyle: {
									color: 'rgba(255,255,255,.25)',
									width: 1
								}
							},
							minorSplitLine: {
								show: true,
								lineStyle: {
									color: 'rgba(255,255,255,.05)',
									width: 1
								}
							}
						},
						yAxis: {
							type: 'category',
							data: ['Neuroticism ', 'Extraversion', 'Openess to Experience', 'Agreeableness ', 'Conscientiousness '],
							axisLabel: {
								fontSize: '0.7rem',
								lineHeight: 10,
								fontFamily: 'Megatoya-Ext-sub',
								color: 'rgba(255,255,255,.65)',
								fontWeight: 'bold',
								formatter: function (value) {
									return value.toUpperCase();
								}
							},
							splitLine: {
								show: false
							}
						},
						series: [
							{
							name: 'Score',
							type: 'bar',
							color: 'rgba(70,70,70,1)',
							data: [55, 116, 94, 100, 84]
							}
						]
					}); 

				// Resize window event for charts
				window.addEventListener("resize", this.resizeChart);
				
				// Update chart size:
				this.resizeChart();

			});

		},
		unmounted(){
			window.removeEventListener("resize", this.resizeChart);
		},
		methods: {
			videoLoaded() {
				this.videoReady = true;
			},
			textAnimation() {
				

				var timeline = [
					{
						id: 'about-title',
						start: 300,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'about-title-2',
						start: 450,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'about-bread',
						start: 750,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
				];
				
				
				timeline.forEach(function(item) {

					if (item.style == 'character') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/.{1}/g);
						
						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == ' ') ? '&nbsp;' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'word') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/\S*/g);

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == '') ? '' : letters[i];

							if (text != ''){
								html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '&nbsp;</span>'
							}

						}

						elem.innerHTML = html;
					}

					if (item.style == 'all') {

						let txtanElems = document.getElementById(item.id);
						let text = txtanElems.textContent;

						const startDelay = item.start;
						const letterDelay = item.segementAnimationDelay;
						const animationDuration = item.segementAnimationDuration;

						const delay = startDelay + letterDelay;

						txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
					}
					

				});
			},
			changeTab(event,id){

				event.preventDefault();

				this.activeTab = id;

				// // Hide all other tabs 
				setTimeout(function(){
					this.resizeChart();
				}.bind(this), 1);

				// If it's background check tab, start loading in the data... 
				if (id == 'compliance'){
					axios.get(this.$store.state.app.apiUrl + '/clearcheck').then(response => {
						const recordsContainer = document.getElementById('records');
						recordsContainer.innerHTML = response.data.data.attributes.records;
					});
				}

				// GA track tab change as page view:
				function toTitleCase(str) {
					return str.replace(/\w\S*/g, function(txt){
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				}
				let tabPageTitle = id.replaceAll(/-/g, ' ') + ' - Profile & CV – Mickie C. Storm';
				tabPageTitle =  toTitleCase(tabPageTitle);
				this.$gtag.pageview({
					'page_title' : tabPageTitle,
					'page_path': '/about-mickie-c-storm/'+id
				});

				this.resizeChart();

			},
			resizeChart(){
				let chartContainer = document.getElementById('chart');
				const scaleFactor = chartContainer.offsetWidth / 1400;
				const translateFactor = (1 - scaleFactor) * 100 / 2;
				const transformStyle = 'translate(-'+translateFactor+'%, -'+translateFactor+'%) scale(' + scaleFactor + ')';
				chartContainer.style.transform = transformStyle;

				const ChartHeight = 1100 * scaleFactor;
				const negativeMargin = 1100 - ChartHeight;
				chartContainer.style.marginBottom = '-' + negativeMargin + 'px';


				// Resize big-five chart
				this.bigFiveChart.resize();
			},
			pageScroll(event){
				
				const yScrollPos = event.target.scrollTop
				
				const yScrollStart = 50;
				const yScrollDistance = 500;

				const yScroll = (yScrollPos < yScrollStart) ? 0 : (yScrollPos - yScrollStart);
				const yScrollPct = (yScroll / yScrollDistance > 1) ? 1 : (yScroll / yScrollDistance).toFixed(2);
				
				if (this.pageScrollPos != 1 - yScrollPct){
					const opacity = (1 - yScrollPct).toFixed(2);
					this.pageScrollPos = opacity;
					const videoElem = document.getElementById('background-video');
					videoElem.style.opacity = opacity;
				}

			}
		}
	}
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

	.about-page {

		.inner-page .content-container p#about-bread {
			margin-bottom: $margin-l;
			display:block;
		}

		ul.list-history {
			padding: 0px;
			padding-right: $margin-xl;
			margin:0px;
			list-style: none;
			max-width:1000px;

			li {
				opacity:0;
				animation-timing-function: cubic-bezier(.19,1,.22,1);
				animation-name: 'historyAnimateIn';
				animation-duration: 1000ms;
				animation-fill-mode: forwards;
			}

		}

		.tabs-container {

			position: relative;
			

			.tab-content {
				position: absolute;
				width:100%;
				top: 0px;
				left:0px;
				opacity: 0;
				transition-duration: 100ms;
				transition-property: opacity;
				pointer-events: none;
				padding-bottom: $margin-l;
				
				&.show {
					pointer-events: auto;
					opacity: 1;
					transition-delay:100ms;
					transition-duration: 500ms;
				}

				&.collapse {
					height: 0px !important;
					overflow:hidden;
				}

				.hr {
					width:100%;
					height:2px; 
					background-color: rgba(255, 255, 255, 0.05);
					margin-top:30px;
					margin-bottom:60px;
				}

				&#profile {

					padding-right:$margin-xl;
					margin-right: $margin-xl;
					max-width: 1400px;

					.psy-summary {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: flex-start;
						align-content: flex-start;
						align-items: flex-start;
						gap:30px;

						h3 {
							margin: 0px;
							padding: 12px 22px;
							border: 2px solid $gray-700;
						}
						
						div {

							a {
								text-decoration: none;
								* {
									cursor: pointer;
								}
								&:hover, &:active {
									text-decoration: none;
								}
							}

							.content {

								height: calc(100% - 44px);

								background-color: $gray-700;
								padding: 22px;

								h4 {
									margin:0px;
									font-weight:500;
									color: $gray-200;
									font-size: $base-font-size * 2;
									line-height: $base-font-size * 2.2 ;
								}

								span.title {
									font-size: $font-size-tiny;
									letter-spacing: $font-size-tiny * $letterspacing-multiplier;
									line-height: $font-size-tiny;
									color: $gray-400;
									font-weight: bold;
									margin:0px;
									margin-top:3px;
									display:block;
									margin-bottom:10px;

								}

								#chart-big-five {
									height:106px;
									width: 100%;
								}


							}

							&:nth-child(2) {
								flex: 0 0 auto;
								width:280px;
								.content {
									display: flex;
									flex-direction: column;
									flex-wrap: nowrap;
									justify-content: center;
									align-content: center;
									align-items: flex-start;
									
								}
							}
							&:nth-child(1) {
								flex: 1 0 auto;
								.content {
									padding-top: 15px;
									padding-bottom:15px;
									width:100%;
								}
							}
						}
					}

					.hmd-title {
						padding: 16px 22px;
						border: 2px solid $gray-700;
						margin-top:70px;

						h3.title.text-larger {
							margin-top:0px;
							margin-bottom:0px;
							line-height: $font-size-larger;

							span {
								color: $gray-300;
								font-size: $font-size-small;
								margin-left: 10px;
								font-weight: normal;
							}
						}

						.title.sub-title {
							font-size: $font-size-small;
							color: $gray-400;
							display:block;
							line-height: $font-size-small;
							margin-top:3px;
						}

					}

					.hmd-content {
						
						padding: 10px 22px 22px 22px ;
						background-color: $gray-700;

						.leadin {
							color: $gray-200;
							font-size: $font-size-normal;
							display:block;
							margin-bottom:8px;
							margin-top:5px;
						}

						ul.col-list {
							
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: flex-start;
							align-content: stretch;
							align-items: stretch;
							gap: 12px;
							width: 100%;
							max-width: 700px;
							margin:0px; 
							padding:0px;
							list-style: none;
							
							li {
								flex: 1 1 auto;
								width: calc(50% - 20px);
								margin:0px;
								padding:0px;
								font-weight: bold;
								font-size: $font-size-normal;
								padding-right:30px;

								span {
									display:block;
									color: $gray-400;
									font-weight: normal;
									font-size: $font-size-small;
									line-height: $font-size-small * 1.25;
								}

							}
					}
				
				
					}

					#chart {
						width:100%;
						max-width:900px;
						margin-top:60px;

						transform: translate(-17.8571%, -17.8571%) scale(0.642857);

						div:first-child {
							//transform: translate(-17.8571%, -17.8571%) scale(0.642857);
						}

						.charttooltip{
							transform: translate(-17.8571%, -17.8571%) scale(1.5);
						}
						

					}

				}

				&#skills {
					display: flex;
					flex-wrap: wrap;	
					gap: 5%;
					width: 100%;
					padding-right: $margin-xl;

					.column {

						flex: 1 1 25%;
						min-width: 320px;

						.section {

							h3 {
								font-size: 1rem;
								letter-spacing: $font-size-normal * $letterspacing-multiplier;
								margin:0px;
								line-height: 1.1rem;
								margin-bottom: $margin-base * 2;
								background-color: $gray-700;
								padding: 12px 10px 10px 10px;
							}

							ul {
								list-style: none;
								padding:0px;
								margin:0px;
								margin-bottom:50px;

								li {
									display: flex;
									flex-direction: row;
									flex-wrap: nowrap;
									justify-content: space-between;
									align-content: flex-start;
									align-items: stretch;
									background-image:url('../assets/icons/level-0.svg');
									background-position: 10px 50%;
									background-repeat: no-repeat;
									background-size:16px;
									height:37px;
									padding-left:36px;
									padding-right:10px;
									border-bottom: 1px solid rgba(255,255,255,0.08);

									&.level-1 {
										background-image:url('../assets/icons/level-1.svg');
									}
									&.level-2 {
										background-image:url('../assets/icons/level-2.svg');
									}
									&.level-3 {
										background-image:url('../assets/icons/level-3.svg');
									}
									&.level-4 {
										background-image:url('../assets/icons/level-4.svg');
									}

									span {
										order: 0;
										flex: 1 0 auto;
										align-self: center;
										font-size: 14px;
										line-height: 14px;
									
										&:nth-child(2) {
											order: 0;
											flex: 0 0 auto;
											align-self: center;
											color: $gray-400;
										}

									}

								}

							}
						}

					}


				}

				&#compliance {

					padding-right:$margin-xl;
					margin-right: $margin-xl;
					max-width: 1400px;

					h3.title {
						margin: 0px;
						padding: 12px 22px;
						border: 2px solid #1e1e1e;
					}

					.clearcheck {
						padding: 30px 22px 20px 22px ;
						background-color: $gray-700;

						div {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: flex-start;
							align-content: flex-start;
							align-items: center;

							img {
								width:200px;
								margin-right: 40px;
								margin-left: 20px;
							}

							span {
								font-size: $font-size-normal;
								line-height: $font-size-normal * 1.25;
								.sub {
									color: $gray-300;
									display:block;
									font-size: $font-size-small;
								}
								a {
									color:$text-color;
									font-weight:bold;
								}
							}

						}

						.hr {
							margin-top:30px;
							margin-bottom:0px;
						}

					}

					.records {
						
						width: 100%;
						column-count: 3;
						column-gap: 40px;
						padding-top: 15px;
						padding-bottom: 15px;
						padding: 10px 22px 22px 22px ;
						background-color: $gray-700;


						div {

							white-space: nowrap;

							h3 {
								font-size: $font-size-normal;
								letter-spacing: $font-size-tiny * $letterspacing-multiplier;
								margin-bottom:8px;
								margin-top:0px;
								white-space: normal;
							}

							ul {
								margin:0px;
								padding:0px; 
								list-style: none;
								margin-bottom:20px;

								li {

									font-size: $font-size-tiny * 1.1;
									display: flex;
									flex-direction: row;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-content: flex-start;
									align-items: flex-start;
									border-bottom:1px solid rgba(255,255,255,.05);
									padding-top:1px;

									span {
										display:block;
										white-space: nowrap;
										text-overflow: ellipsis ellipsis;
									}
								
									span:nth-child(1){
										flex: 1 1 auto;
										overflow: hidden;
										margin-right: 20px;
										color: $gray-300;
									}
									span:nth-child(2){
										flex: 0 1 auto;
										color:rgb(166, 198, 100);
									}
								}


							}
						}
					}

				}

			}

		}

		.charttooltip {

			border: none !important;
			border-width: 0px !important;
			border-color: none !important;
			border-style: none !important;
			border-radius: 5px !important;
			padding:26px !important;
			padding-right: 35px !important;


			span {
				display:block;
				line-height: 1.1rem * 1.8;
				font-family: $font-text;
				text-transform: uppercase;
				color: $gray-800;
			}
			.groupid {
				font-family: $font-header;
				font-size: $font-size-small * 1.8;
				font-weight: bold;
				padding-bottom: 3px;
				padding-top: 3px;
				border-bottom: 1px solid rgba(0,0,0,.1);
				margin-bottom: 7px;
			}
			.values {
				font-size: $font-size-small * 1.8;
			}
			.info {
				font-size: $font-size-tiny * 1.8
			}
		}

	}



</style>