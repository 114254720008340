<template>
  <li :class="'item-'+uniqueId" :style="'animation-delay:' + (index*200) + 'ms'">
    <a class="fixed-width" href="#" @click="toggleTab($event)">
      <div class="logo">
        <img @load="showLoadedImage($event)" :alt="companyName + ' logo'" :src="this.$store.state.app.cdnUrl + image" />
      </div>
      <div class="description">
        <h3>{{jobTitle}}</h3>
        <h3 class="light">{{companyName}}</h3>
        <span class="details">{{formatDate(startDate)}} <span class="arw">➜</span> {{formatDate(endDate)}} &nbsp;•&nbsp; {{city}}, {{country}} &nbsp;•&nbsp; {{companyType}}</span>
				<div class="tab" v-html="description"></div>
      </div>
      <div class="actions">
        <a href="#" alt="Expand"><img src="../../assets/icons/arrow.svg"></a>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: "HistoryListItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    uniqueId: {
      type: Number,
      default: 0,
    },
    companyName: {
      type: String,
      default: "Business Entity Ltd.",
    },
    companyType: {
      type: String,
      default: "",
    },
		jobTitle: {
      type: String,
      default: "Job Position",
    },
		startDate: {
      type: String,
      default: '1980',
    },
		endDate: {
      type: String,
      default: '2022',
    },
		city: {
      type: String,
      default: "City Name",
    },
		country: {
      type: String,
      default: "Country Name",
    },
		description: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
		image: {
			type: String,
      default: "",
		}
  },
  data() {
    return {
      loadingOld: false,
    };
  },
	methods: {
			toggleTab(event) {
				event.preventDefault();
        let item = event.target.querySelector('.actions');
				let tab = event.target.querySelector('.tab');
				tab.classList.toggle("open");
        item.classList.toggle("open");
			},
			showLoadedImage(event) {
				let image = event.target
				image.classList.add("show")
			},
			formatDate(date){
				const timestamp = Date.parse(date);
				var dateObject = new Date(timestamp);
				
				let year = dateObject.getFullYear();

				return (isNaN(year)) ? 'Present' : year;

			}
	}
};
</script>

<style lang="scss">
@import "../../scss/abstracts/_variables.scss";

ul.list-history li {
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;

  &.item-1 a .logo img {
    max-width: 85px;
  }

  &.item-2 a .logo img {
    max-width: 80px;
  }
  &.item-12 a .logo img {
    max-width: 95px;
  }

	&:hover a .logo img {
		opacity: 0.8;
		transition-duration: 500ms;
	}


  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    text-decoration: none;
    color: $text-color;

		* {
			pointer-events: none; 
			cursor:default;
		}

		&:hover .logo img.show {
			opacity: 0.75;
			transition-duration: 100ms;
			transform: scale3D(1.05,1.05,1.05);
		}


    .logo {
      flex: 0 0 auto;
      align-self: auto;
      width: 220px;
      min-height: 100px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      background-color: $gray-700;

      img {
        width: 100%;
        max-width: 124px;
        max-height: 32px;
        opacity: 0;
				transform: scale3D(1,1,1);
				transition-property: transform, opacity;
				transition-duration: 500ms;
        filter: invert(100%);
				&.show {
					opacity: 0.45;
				}

      }


    }
    .description {
      flex: 1 1 auto;
      align-self: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      padding-left: $margin-base * 4;
			padding-top:25px;
      background: linear-gradient(
        90deg,
        rgba(22, 22, 22, 1) 0%,
        rgba(0, 0, 0, 0) 99%
      );
      h3 {
        margin: 0px;
        padding: 0px;
        line-height: 120%;
        font-size: 1rem;
        letter-spacing: $font-size-small * $letterspacing-multiplier;
        &.light {
          font-weight: 400;
          color: $gray-300;
					font-size: $base-font-size * 0.9;
					padding-top:2px;
        }
      }
      span.details {
        display: block;
        margin-top: $margin-s;
        line-height: 100%;
        color: $gray-400;
        font-family: $font-header;
        font-size: $font-size-tiny;
        letter-spacing: $font-size-tiny * 0.05;
				text-transform: uppercase;
      }
    }
    .actions {
      flex: 0 0 auto;
      align-self: auto;

      a {
        width: 35px;
        height: 35px;
        display: flex;
        background: $gray-600;
				margin-top: 19px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;

        img {
          width:15px;
          transform:rotateZ(0deg);
          transition-property: transform;
          transition-duration: 150ms;
          opacity:0.6;
        }

      }

      &.open a img {
        transform:rotateZ(-90deg);
      }

    }
  }

  .tab {
		margin-top:0px;
		margin-bottom:0px;
		padding-top:0px;
		font-size: $base-font-size * 0.9;
		color: $gray-200;
    overflow: hidden;
		height:0px;
    width: 100%;
    max-height: 0px;
		opacity: 0;
    transition-property: all;
    transition-duration: 200ms;
		border-top: 0px solid rgba(255,255,255,0);
    &.open {
			max-height:100vh;
			height:100%;
			margin-top:20px;
			margin-bottom:20px;
			padding-top:15px;
			opacity:1;
			border-top: 1px solid rgba(255,255,255,0.1);

    }
  }
}
</style>